/* ------------------------
    Banner
------------------------*/
.fullscreen-banner {
    position: relative;
    min-height: 800px;
    height: 90vh;
}

[data-bg-img] {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.align-center {
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 9;
}

.fullscreen-banner .owl-carousel,
.fullscreen-banner .owl-stage-outer,
.fullscreen-banner .owl-stage,
.fullscreen-banner .owl-item,
.fullscreen-banner .item {
    height: 50%;
}

.banner-2 {
    padding: 8rem 0
}

.banner-bg {
    width: 50%;
    height: 120%;
    position: absolute;
    z-index: -1;
    right: -50px;
    top: -10%;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    z-index: 9;
    overflow: hidden;
}


.bnr-title.large {
    font-size: 25rem;
    line-height: 25rem
}

.bnr-title2 {
    background: -webkit-linear-gradient(transparent, transparent), url(../images/bg/bubbles.png) repeat;
    background-size: 200px auto;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: background 10s infinite linear;
    font-size: 110px;
    line-height: 130px;
    background-color: #0a1245;
}

.error-page .bnr-title2 {
    font-size: 300px;
    line-height: 300px;
}

/*Banner Left*/
.hero-left {
    position: absolute;
    left: 0;
    top: 0;
    background: #0083ff;
    width: 80px;
    z-index: 9;
    overflow: hidden;
}

.hero-social li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-table;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-top: none;
    position: relative;
    text-align: center;
}

.hero-social li:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-social li a {
    position: relative;
    z-index: 2;
    color: #ffffff;
}

.hero-social li::before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    top: 0;
    background: #00f2aa;
    z-index: 1;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

.hero-social li:hover::before {
    width: 100%;
}

.hero-left .scroll-down-arrow {
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    background: url(../images/scroll-down-arrow.svg) no-repeat;
    background-size: cover;
    left: 50%;
    margin-left: -20px;
    z-index: 2;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
    bottom: 80px;
}

.hero-left .scroll-down-arrow:after {
    content: "";
    width: 1px;
    height: 200px;
    background: #fff;
    display: block;
    position: absolute;
    top: 40px;
    left: 19px;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .0 -pl {
        padding-left: 80px
    }
}

/* -------------------------------
   Background Position & Size
-------------------------------*/
.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.bg-pos-l {
    background-position: left;
}

.bg-pos-r {
    background-position: right;
}

.bg-pos-rt {
    background-position: right top;
}

.bg-pos-lt {
    background-position: left top;
}

.bg-pos-rb {
    background-position: right bottom;
}

.bg-pos-lb {
    background-position: left bottom;
}

.bg-repeat {
    background-repeat: repeat;
}


/* ------------------------
    Countdown
------------------------*/
.countdown {
    margin: 50px 0;
    width: 100%;
}

.countdown>li {
    font-weight: 600;
    list-style-type: none;
    padding-right: 50px;
}

.countdown>li:last-child {
    padding-right: 0;
}

.countdown>li span {
    font-size: 80px;
    font-weight: 700;
    line-height: 90px;
    display: inline-block;
    color: #0083ff;
}

.countdown>li p {
    font-size: 20px;
    display: block;
    line-height: 30px;
    margin-bottom: 0;
    color: #989898;
}


/* ------------------------
    Terms and Conditions
------------------------*/
.terms-and-conditions p {
    margin-bottom: 25px;
}

.terms-and-conditions ul.list {
    margin-bottom: 20px;
    display: block;
}

.terms-and-conditions ul.list li i {
    padding-right: 12px;
    font-size: 0.938rem;
}


/* ------------------------
    Privacy Policy
------------------------*/
.privacy-policy p {
    margin-bottom: 25px;
}

.privacy-policy ul.list {
    margin-bottom: 20px;
    display: block;
}

.privacy-policy ul.list li i {
    padding-right: 12px;
    font-size: 0.938rem;
}


/* ------------------------
    Contact
------------------------*/
.contact-info>i {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 450px;
    line-height: 450px;
    color: rgba(255, 255, 255, 0.050);
}

.contact-media {
    position: relative;
    padding-left: 70px;
}

.contact-media i {
    position: absolute;
    left: 0;
    font-size: 40px;
    line-height: 40px;
    top: 0;
    color: #00f2aa;
}

.contact-media h6 {
    display: block;
    margin-bottom: 5px;
    color: #00f2aa
}

.contact-media span {
    margin-bottom: 0;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
}

.contact-media a {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    display: block;
}

.contact-media a:hover {
    color: #ffffff;
    text-decoration: underline !important;
}

label {
    margin-bottom: 5px;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

.form-control {
    height: 50px;
    border-radius: 0;
    font-size: 13px;
    color: #0a1245;
    background-color: inherit;
    border: 1px solid #f6f7fb;
    ;
}

.theme-bg .form-control,
.dark-bg .form-control {
    background-color: inherit;
    border-bottom-color: #ffffff;
}

.dark-bg .form-control,
.theme-bg .form-control {
    color: #ffffff;
}

.grey-bg .form-control {
    background: #ffffff
}

textarea.form-control {
    height: 150px;
}

.form-control:focus {
    box-shadow: none;
    background-color: inherit;
    border-color: #00f2aa;
}

select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 50px !important;
}

iframe {
    width: 100%;
    border: none;
    pointer-events: auto;
}

.iframe-h {
    height: 500px !important;
}

.iframe-h-2 {
    height: 600px !important;
}

.form-control::-webkit-input-placeholder {
    color: #989898;
}

.form-control::-moz-placeholder {
    color: #989898;
}

.form-control:-ms-input-placeholder {
    color: #989898;
}

.form-control:-moz-placeholder {
    color: #989898;
}

.dark-bg .form-control::-webkit-input-placeholder,
.theme-bg .form-control::-webkit-input-placeholder {
    color: #ffffff;
}

.dark-bg .form-control::-moz-placeholder,
.theme-bg .form-control::-moz-placeholder {
    color: #ffffff;
}

.dark-bg .form-control:-ms-input-placeholder,
.theme-bg .form-control:-ms-input-placeholder {
    color: #ffffff;
}

.dark-bg .form-control:-moz-placeholder,
.theme-bg .form-control:-moz-placeholder {
    color: #ffffff;
}

.help-block.with-errors {
    position: absolute;
    color: red;
    right: 15px;
    font-size: 10px;
    line-height: 14px;
}

.success {
    padding: 1em;
    margin-bottom: 0.75rem;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #468847;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%
}

.error {
    padding: 1em;
    margin-bottom: 0.75rem;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid rgba(185, 74, 72, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    -webkit-text-fill-color: #0a1245 !important;
    transition: background-color 5000s ease-in-out 0s;
}

.theme-bg input:-webkit-autofill,
.theme-bg textarea:-webkit-autofill,
.theme-bg select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #0083ff inset !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s;
}

.dark-bg input:-webkit-autofill,
.dark-bg textarea:-webkit-autofill,
.dark-bg select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #12141d inset !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s;
}

.map {
    height: 500px;
}

.map iframe {
    border: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

.contact-2 .map {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
}


/* ------------------------
    Video
------------------------*/
.video-btn {
    position: relative;
    display: inline-block;
}

.video-btn-pos {
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto !important;
}

.play-btn {
    position: relative;
    background: #00f2aa;
    border: 2px solid #00f2aa;
    border-radius: 50%;
    color: #ffffff;
    font-size: 30px;
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.video-btn .play-btn:after,
.video-btn .play-btn:before {
    content: '';
    border: 1px solid;
    border-color: inherit;
    width: 150%;
    height: 150%;
    border-radius: 50%;
    position: absolute;
    left: -25%;
    top: -25%;
    opacity: 1;
    -webkit-animation: 1s videomodule-anim linear infinite;
    animation: 1s videomodule-anim linear infinite;
}

.video-btn .play-btn:before {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}


/* ------------------------
    Side Background
------------------------*/
.image-column {
    position: absolute;
    left: 0px;
    top: 50%;
}

.image-column.right {
    left: inherit;
    right: 0;
}


/* ------------------------
    List Icon
------------------------*/
.list-icon li {
    position: relative;
    padding-left: 40px;
    width: 48%;
    display: inline-block;
    vertical-align: top;
}

.list-icon li i {
    background-color: rgba(19, 96, 239, .1);
    color: #0083ff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    color: #0083ff;
    font-size: 14px;
    display: inline-block;
}

.theme-bg .list-icon li i {
    color: #ffffff;
}


/* ------------------------
    Shop Page
------------------------*/
.product-item {
    text-align: center;
}

.product-img {
    overflow: hidden;
    position: relative;
    border-radius: .25rem
}

.product-img img {
    width: 100%;
}

.product-item:hover .product-img img {
    transform: scale(1.08) rotate(1deg);
}

.product-item .product-overlay {
    position: absolute;
    left: 50%;
    width: 100%;
    bottom: -50%;
    transform: translateX(-50%);
}

.product-item:hover .product-overlay {
    bottom: 5%
}

.product-overlay li {
    margin: 0 1px;
    display: inline-block;
}

.product-overlay ul li a {
    padding: 10px;
    background: #ffffff;
    border-radius: .25rem;
    display: inline-block;
    text-align: center;
    color: #0a1245;
}

.product-overlay ul li a:hover {
    background: #0083ff;
    color: #ffffff;
}

.product-desc {
    padding-top: 15px;
}

.product-name {
    font-size: 16px;
    font-weight: normal;
    color: #0a1245;
    display: block;
    margin-bottom: 7px;
}

.product-price {
    color: #0083ff;
    font-weight: 600;
}

.product-price span {
    font-size: 14px;
    font-weight: 400;
    color: #b2b2b2;
    text-decoration: line-through;
}

.product-list .product-desc {
    padding-top: 0;
    text-align: left;
}


.form-check-input:checked {
    background-color: #0083ff;
    border-color: #0083ff;
}

.widget-color [type="radio"]:checked,
.widget-color [type="radio"]:not(:checked) {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.widget-color .form-check-label {
    width: 36px;
    height: 36px !important;
    border-radius: .25rem;
    padding: 0 !important;
}

.widget-color .form-check-input[type="radio"]:checked+label,
.widget-color .form-check-input[type="radio"]:not(:checked)+label,
label.btn input[type="radio"]:checked+label,
label.btn input[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 1.5625rem;
    line-height: 1.5625rem;
    transition: .28s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.widget-color .form-check-input[type="radio"]+label::after,
.widget-color .form-check-input[type="radio"]+label::before,
label.btn input[type="radio"]+label::after,
label.btn input[type="radio"]+label::before {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    width: 40px;
    height: 40px;
    z-index: 0;
    transition: .28s ease;
    z-index: -1;
    border-radius: .25rem;
}

.widget-color .form-check-input[type="radio"]:checked+label::after,
label.btn input[type="radio"]:checked+label::after {
    transform: scale(1.02);
}

.widget-color .form-check-input[type="radio"]:checked+label::after {
    background-color: #00f2aa;
}

.widget-size input[type="radio"] {
    visibility: hidden;
    position: absolute;
}

.widget-size label {
    border: 1px solid #f6f7fb;
    margin-bottom: 0;
    display: inline-block;
    height: 40px;
    min-width: 40px;
    text-align: center;
    font-size: 14px;
    color: #2b2b2b;
    font-weight: 500;
    cursor: pointer;
    border-radius: .25rem;
    line-height: 40px;
}

.widget-size input[type="radio"]:checked+label {
    background: #0083ff;
    color: #fff;
}

.widget-color li {
    list-style-type: none;
    display: inline-block;
    margin-right: 1%;
    margin-bottom: 20px;
    width: 22%;
    text-align: center;
}

.widget-color li:nth-child(4n+4) {
    margin-right: 0;
}

.widget-size li {
    list-style-type: none;
    display: inline-block;
    width: 23%;
    text-align: center;
    margin-bottom: 20px;
}

.widget-size li:nth-child(4n+4) {
    margin-right: 0;
}

input.form-product {
    -moz-appearance: textfield;
    height: 40px;
    border: none;
    background: #ffffff;
    text-align: center;
    width: 150px;
    border-top: 1px solid #f6f7fb;
    border-bottom: 1px solid #f6f7fb;
    color: #0a1245;
    vertical-align: middle;
}

.btn-product {
    background: none;
    color: #0a1245;
    border: 1px solid #f6f7fb;
    height: 40px;
    width: auto;
    padding: 0 10px;
    font-size: 13px;
    cursor: pointer;
}


.view-filter a {
    color: #0a1245;
    padding-right: 8px;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
}

.view-filter a.active {
    color: #0083ff;
}

.product-item .sale-label {
    color: #fff;
    font-size: 10px;
    background: #0083ff;
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: .25rem
}

.total-rating .box-total {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #f6f7fb;
}

.total-rating .rating-list li a {
    font-size: 14px;
    color: #989898;
}

.total-rating .rating-list li a i {
    color: #0083ff;
}

.review-list .media-body p {
    padding: 0;
    margin: 15px 0;
}

.cart-table {
    border: 1px solid #f6f7fb;
}

.cart-table.table thead tr th {
    border-top: 0px;
    border-bottom: 0px !important;
    text-align: center;
}

.cart-table.table tbody tr td {
    border-color: #f6f7fb;
    padding: 30px;
    vertical-align: middle;
    align-self: center;
    text-align: center;
}

.cart-table.table tbody tr td h5 {
    font-size: 14px;
    color: #989898;
    margin-bottom: 0px;
}

.cart-total {
    font-size: 16px;
    color: #0a1245;
}

.btn-delet {
    border: none;
    background: none;
}

.dark-bg .cart-total,
.theme-bg .cart-total {
    color: #ffffff
}


/* ------------------------
    Animation
------------------------*/
.hero-animation {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.hero-animation span {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #a03ebd;
    border-radius: 50%;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
}

.hero-animation span:nth-child(2n) {
    border-color: #0051f4;
}

.hero-animation span:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 40vh;
    border-color: #e46d6f;
}

.hero-animation span:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 50vh;
    border-color: #ffffff;
}

.hero-animation span:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
    border-color: #fa0d5b;
}

.hero-text {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%
}

.hero-text span {
    position: absolute;
    top: 10vh;
    left: 5vw;
    color: #a03ebd;
    transform-origin: top left;
    transform: scale(0) translate(-50%, -50%);
    animation: h-text 12s ease-in forwards infinite;
}

.hero-text span:nth-child(2) {
    animation-delay: 2s;
    left: 10vw;
    top: 40vh;
    color: #0051f4
}

.hero-text span:nth-child(3) {
    animation-delay: 4s;
    left: 20vw;
    top: 85vh;
    color: #e46d6f;
}

.hero-text span:nth-child(4) {
    animation-delay: 6s;
    left: 45vw;
    top: 80vh;
    color: #ffffff
}

.hero-text span:nth-child(5) {
    animation-delay: 8s;
    left: 50vw;
    top: 10vh;
    color: #fa0d5b
}

.hero-text span:nth-child(6) {
    animation-delay: 10s;
    left: 75vw;
    top: 20vh;
    color: #fa0d5b
}

.hero-text span:nth-child(7) {
    animation-delay: 12s;
    left: 95vw;
    top: 70vh;
    color: #fa0d5b
}

/*Inside Text*/
.inside-text {
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 50%;
    white-space: nowrap;
    width: 100%;
    z-index: 0;
}

.inside-text span {
    text-transform: uppercase;
    display: inline-block;
    font-size: 200px;
    color: transparent;
    font-weight: bold;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.080);
}

.dark-bg .inside-text span {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
}

/*Background Animation*/
.bg-effect {
    top: -50%;
    content: "";
    position: absolute;
    right: -30%;
    z-index: -1;
}

.dot-bg {
    position: absolute;
    left: -15%;
    top: 0;
    animation: blink 1s ease-out infinite alternate;
}

.round-shape {
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 4vw;
    bottom: 0;
}

.img-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
}

#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5
}

/*Ripple Animation*/
.ripple-background {
    position: absolute;
    left: 50%;
    top: 0
}

.circle {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.030);
    animation: ripple 15s infinite;
}

.shade5 {
    width: 200px;
    height: 200px;
    left: -100px;
    bottom: -100px;
}

.shade4 {
    width: 400px;
    height: 400px;
    left: -200px;
    bottom: -200px;
}

.shade3 {
    width: 600px;
    height: 600px;
    left: -300px;
    bottom: -300px;
}

.shade2 {
    width: 800px;
    height: 800px;
    left: -400px;
    bottom: -400px;
}

.shade1 {
    width: 1000px;
    height: 1000px;
    left: -500px;
    bottom: -500px;
}

.shade1 {
    opacity: 0.2;
}

.shade2 {
    opacity: 0.5;
}

.shade3 {
    opacity: 0.7;
}

.shade4 {
    opacity: 0.8;
}

.shade5 {
    opacity: 0.9;
}

/*Line Drop Animation*/
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
}

.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.lines .line:nth-child(1) {
    margin-left: -25%;
}

.lines .line:nth-child(3) {
    margin-left: 25%;
}

.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-fill-mode: none;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}


/* ------------------------
    IE Css
------------------------*/
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

    .bnr-title,
    .theme-bg .cases-desc span {
        background: none;
        color: #ffffff;
    }

    .bnr-title2,
    .cases-desc span,
    .featured-item.style-3 .featured-icon i {
        background: none;
        color: #0083ff;
    }

    .section-title h6 {
        background: none;
        color: #00f2aa;
    }

    .featured-item.style-4 .featured-icon {
        background: none;
        color: #0083ff;
    }

    .featured-item.style-4:hover .featured-icon,
    .featured-item.style-4.active .featured-icon {
        color: #ffffff;
    }
}